<template>
    <div class="modal fade show add-note-modal" aria-labelledby="applyTdsVds">
        <div class="modal-dialog modal-dialog-centered modal-custom" ref="modalRef">
            <div class="modal-content">
                <div class="row">
                    <div class="col-12">
                        <div class="d-flex justify-content-between align-items-center px-1 my-1 align-item-center">
                            <h1 class="mb-0">Apply TDS VDS</h1>
                            <button @click="closeModal" type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                    </div>
                </div>

                <hr class="mt-0">

                <div class="modal-body pb-5">
                    <div class="row gy-2 px-2">
                        <div class="col-12 mr-top-5">
                            <label class="form-label">Account Head</label>
                            <div class="product-select">
                                <v-select
                                    placeholder="Select Head"
                                    :options="accountHeads"
                                    label="name"
                                    :reduce="name => name.id"
                                    v-model="billData.account_head_id"
                                />
                            </div>
                        </div>
                        <div class="col-12 col-md-4 col-lg-4">
                            <label class="form-label">Amount</label>
                            <div class="input-group">
                                <input
                                    type="number"
                                    min="1"
                                    name="amount"
                                    placeholder="Amount"
                                    class="form-control"
                                    v-model="billData.amount"
                                    @input="onChangeBaseInfo"
                                />
                            </div>
                        </div>
                        <div class="col-12 col-md-4 col-lg-4">
                            <label class="form-label">Discount Amount</label>
                            <div class="input-group">
                                <input
                                    type="number"
                                    min="1"
                                    name="discount_amount" 
                                    placeholder="Enter discount amount"
                                    class="form-control"
                                    v-model="billData.discount_amount"
                                    @input="onChangeBaseInfo"
                                />
                            </div>
                        </div>
                        <div class="col-12 col-md-4 col-lg-4">
                            <label class="form-label">After Discount Amount</label>
                            <div class="input-group">
                                <input
                                    type="number"
                                    min="1"
                                    disabled
                                    name="after_discount_amount" 
                                    placeholder="Enter discount amount"
                                    class="form-control"
                                    v-model="amountAfterDiscount"
                                />
                            </div>
                        </div>

                        <hr>

                        <div class="col-12 mr-top-5">
                            <div class="summary-card">
                                <table class="table">
                                    <tbody>
                                        <tr>
                                            <td>VAT Amount</td>
                                            <td class="currency">{{ commaFormat(vatAmount) }}</td>
                                        </tr>
                                        <tr class="border-top">
                                            <td>Total Bill Amount</td>
                                            <td class="currency">{{ commaFormat(totalBillAmount) }}</td>
                                        </tr>
                                        <tr>
                                            <td class="d-flex justify-content-start align-items-center">
                                                <span class="me-2">VDS / Mushak 6.3 Amount</span>
                                                <div class="select-head">
                                                    <v-select
                                                        v-model="vdsAccountHeadId"
                                                        placeholder="Select VDS Payable"
                                                        class="w-80"
                                                        :options="vdsPayableHeads"
                                                        label="name"
                                                        :reduce="(name) => name.id"
                                                    />
                                                </div>
                                            </td>
                                            <td class="currency">{{ commaFormat(vatAmount) }}</td>
                                        </tr>
                                         <tr>
                                            <td class="d-flex justify-content-start align-items-center">
                                                <span class="me-5">TDS Amount</span>
                                                <div class="select-head">
                                                    <v-select
                                                        v-model="tdsAccountHeadId"
                                                        placeholder="Select TDS Payable"
                                                        class="w-80"
                                                        :options="tdsPayableHeads"
                                                        label="name"
                                                        :reduce="(name) => name.id"
                                                    />
                                                </div>
                                            </td>
                                            <td class="currency">{{ commaFormat(tdsAmount) }}</td>
                                        </tr>
                                        <tr class="highlight-row">
                                            <td>Net Payable Amount</td>
                                            <td class="currency d-flex justify-content-end">
                                                <input
                                                    min="1"
                                                    class="form-control text-right w-50"
                                                    name="vat_amount"
                                                    type="number"
                                                    placeholder="Net Payable Amount"
                                                    v-model="netSupplierPayment"
                                                    @input="onChangeSupplierAmount"
                                                >
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>

                        <div class="col-12 mr-top-5">
                            <div class="table-responsive">
                                <table class="table custom-table-padding">
                                    <thead>
                                        <tr>
                                            <th width="20%">HS / Service Code</th>
                                            <th width="30%">VDS Head</th>
                                            <th width="12%">Base Amount</th>
                                            <th width="12%">VDS rate</th>
                                            <th width="12%">VDS amount / Mushak 6.3</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>
                                                <v-select
                                                    placeholder="Select Service Code"
                                                    label="code"
                                                    v-model="vdsFormData.id"
                                                    :options="vdsRateList"
                                                    :reduce="item => item.id"
                                                />
                                            </td>
                                            <td>
                                                <v-select
                                                    placeholder="Select VDS Head"
                                                    label="name"
                                                    v-model="vdsFormData.id"
                                                    :options="vdsRateList"
                                                    :reduce="item => item.id"
                                                />
                                            </td>
                                            <td class="text-right">
                                                <span>{{ commaFormat(amountAfterDiscount) }}</span>
                                            </td>
                                            <td class="text-right">
                                                <span>{{ vdsFormData.rate }}</span>
                                            </td>
                                            <td class="text-right">
                                                <span>{{ commaFormat(vatAmount) }}</span>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>

                        <div class="col-12 mr-top-5">
                            <div class="table-responsive">
                                <table class="table custom-table-padding">
                                    <thead>
                                        <tr>
                                            <th width="20%">TDS Section Ref</th>
                                            <th width="30%">TDS Head</th>
                                            <th width="12%">Base Amount</th>
                                            <th width="12%">TDS rate</th>
                                            <th width="12%">TDS amount</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>
                                                <v-select
                                                    placeholder="Select TDS Section Ref"
                                                    label="code"
                                                    v-model="tdsFormData.id"
                                                    :options="tdsRateList"
                                                    :reduce="item => item.id"
                                                />
                                            </td>
                                            <td>
                                                <v-select
                                                    placeholder="Select TDS Head"
                                                    label="name"
                                                    v-model="tdsFormData.id"
                                                    :options="tdsRateList"
                                                    :reduce="item => item.id"
                                                />
                                            </td>
                                            <td class="text-right">
                                                <span>{{ commaFormat(amountAfterDiscount) }}</span>
                                            </td>
                                            <td class="text-right">
                                                <span>{{ tdsFormData.rate }}</span>
                                            </td>
                                            <td class="text-right">
                                                <span>{{ commaFormat(tdsAmount) }}</span>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>

                        <div class="col-12 mr-top-5">
                            <div class="mb-1">
                                <label class="form-label" for="description">TDS VDS Note</label>
                                <vField
                                    as="textarea"
                                    class="form-control"
                                    name="description"
                                    rows="2"
                                    v-model="tdsVdsNote"
                                />
                            </div>
                        </div>
                        
                        <div class="col-12 text-center">
                            <SubmitCancelBtn
                                @onSubmit="submit"
                                @onCancel="closeModal"
                                :loading="loading"
                                submitTxt="Save"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { inject, onMounted, reactive, ref, watch } from "vue";
import { useStore } from "vuex";
import { useRoute } from 'vue-router';
import figureFormatter from "@/services/utils/figureFormatter";
import { computed } from "vue";
import SubmitCancelBtn from '@/components/atom/SubmitCancelBtn.vue';
import cloneDeep from "lodash/cloneDeep";
import handlePurchaseAndSales from "@/services/modules/purchase";
import AsyncSelect from "@/components/molecule/input-field/AsyncSelect.vue";
import round from "lodash/round";

const store = useStore();
const route = useRoute();
const loading = ref(false);
const showError = inject('showError');
const showSuccess = inject('showSuccess');
const { commaFormat } = figureFormatter();
const {fetchProductList} = handlePurchaseAndSales()

const props = defineProps({
    data: {
        type: Object || null,
        default: null
    },
    tdsRateList: {
        type: Array,
        default: []
    },
    vdsRateList: {
        type: Array,
        default: []
    },
    onSaveTdsVds: {
        type: Function,
        default: () => {}
    },
    tdsPayableHeads: {
        type: Array,
        default: []
    },
    vdsPayableHeads: {
        type: Array,
        default: []
    },
    accountHeads: {
        type: Array,
        default: []
    }
})

const emit = defineEmits(['onSaveTdsVds']);

const getVatFormData = () => {
    return {
        id: '',
        rate: 0
    }
}

const vdsFormData = ref(getVatFormData());
const tdsFormData = ref(getVatFormData());
const billData = ref(cloneDeep(props.data));
const tdsAccountHeadId = ref(null);
const vdsAccountHeadId = ref(null);
const netSupplierPayment = ref(0);
const tdsVdsNote = ref(null);

const amountAfterDiscount = computed(() => {
    const discountAmount = billData.value.discount_amount === '' ? 0 : parseFloat(billData.value.discount_amount)
    if(!discountAmount || discountAmount === 0) {
        return billData.value.amount;
    }
    return billData.value.amount - discountAmount
});

const vatAmount = computed(() => {
    return round((amountAfterDiscount.value * parseFloat(vdsFormData.value.rate) / 100), 2);
});

const tdsAmount = computed(() => {
    return round((amountAfterDiscount.value * parseFloat(tdsFormData.value.rate) / 100), 2);
});

const closeModal = () => {
    store.state.isModalOpen = false;
}

const totalBillAmount = computed(() => {
    return round((amountAfterDiscount.value + vatAmount.value), 2);
})

const netPayableAmount = computed(() => {
    return round((totalBillAmount.value - vatAmount.value - tdsAmount.value), 2);
})

const onChangeBaseInfo = () => {
    netSupplierPayment.value = netPayableAmount.value;
}

const onChangeSupplierAmount = () => {
    const baseAmount = netSupplierPayment.value / (100 - parseFloat(tdsFormData.value.rate)) * 100;
    const amount = baseAmount + parseFloat(billData.value.discount_amount || 0);
    billData.value.rate = round((amount / billData.value.quantity), 2);
}

const submit = () => {

    let data = {
        amount: billData.value.amount,
        account_head_id: billData.value.account_head_id,
        tds_rate_id: tdsFormData.value.id,
        vds_rate_id: vdsFormData.value.id,
        tds_amount: tdsAmount.value,
        vds_amount: vatAmount.value,
        vat_amount: vatAmount.value,
        discount_amount: billData.value.discount_amount,
        after_discount_amount: amountAfterDiscount.value,
        net_payable_amount: netPayableAmount.value,
        is_vat_added: true,
        tds_account_head_id: tdsAccountHeadId.value,
        vds_account_head_id: vdsAccountHeadId.value,
        tds_vds_note: tdsVdsNote.value
    }

    emit('onSaveTdsVds', data);
    closeModal();
}

const setInitialData = () => {
    vdsFormData.value.id = billData.value.tds_id || null;
    tdsFormData.value.id = billData.value.vds_id || null;

    if(billData.value.tds_vds_note) {
        tdsVdsNote.value = billData.value.tds_vds_note;
    }
}

const setInitialTdsVds = () => {
    let tdsHeadId = billData.value.tds_ledger ? billData.value.tds_ledger.account_head_id : (props.tdsPayableHeads.length ? props.tdsPayableHeads[0].id : null);
    let vdsHeadId = billData.value.vds_ledger ? billData.value.vds_ledger.account_head_id : (props.vdsPayableHeads.length ? props.vdsPayableHeads[0].id : null);

    tdsAccountHeadId.value = tdsHeadId;
    vdsAccountHeadId.value = vdsHeadId;
}

watch(() => vdsFormData.value.id, (newValue) => {
    if(! newValue) {
        vdsFormData.value.rate = 0;
        return;
    }

    const vat = props.vdsRateList.find(item => item.id == newValue);
    if(! vat) return;

    vdsFormData.value.rate = vat.rate;
    onChangeBaseInfo();
})

watch(() => tdsFormData.value.id, (newValue) => {
    if(! newValue) {
        tdsFormData.value.rate = 0;
        return;
    }

    const vat = props.tdsRateList.find(item => item.id == newValue);
    if(! vat) return;

    tdsFormData.value.rate = vat.rate;
    onChangeBaseInfo();
})

onMounted(() => {
    setInitialData();
    setInitialTdsVds();

    netSupplierPayment.value = netPayableAmount.value;
})

</script>

<style scoped>
.select-head {
    width: 70%;
}
.table-responsive {
    overflow-x: visible !important;
}
.modal-custom{
    max-width: 75vw;
}
.add-note-modal {
    display: block; 
    background: #1c1d1c33
}
.mr-top-5 {
    margin-top: 8px;
}
.highlight-row {
    font-weight: bold;
}
.net-negative {
    color: #dc3545;
}
td {
    padding: 5px 0;
}
td:first-child {
    padding-right: 8px;
}
.currency {
    text-align: right;
}
table {
    width: 100%;
    border-collapse: collapse;
}
.border-bottom {
    border-bottom: 1px solid #dee2e6;
}
.product-select {
    width: 70%;
}
.account-head {
    display: flex;
    justify-content: end;
    align-items: center;
}
</style>
